import React, {useState} from 'react';
import {getRoutes} from "../../../components/shared/routes";
import Layout from '../../../components/shared/Layout';
import SEO from '../../../components/shared/SEO';
import css from "./dcode_gallery.module.scss";
import AdditionalInformation
    from "../../../components/shared/additionalInformation/additionalInformation";
import Footer from "../../../components/shared/Footer/Footer";
import {graphql, useStaticQuery} from "gatsby";
import {getImagesFluid, renderImageByFileName} from "../../../components/componentsUtils";
import dcodeGallery1 from "../../../images/sections/dcodeGallery/Dcode_gallery_1.jpg"
import dcodeGallery2 from "../../../images/sections/dcodeGallery/Dcode_gallery_2.jpg"
import dcodeGallery3 from "../../../images/sections/dcodeGallery/Dcode_gallery_3.jpg"
import dcodeGallery4 from "../../../images/sections/dcodeGallery/Dcode_gallery_4.png"
import dcodeGallery5 from "../../../images/sections/dcodeGallery/Dcode_gallery_5.png"
import dcodeGallery6 from "../../../images/sections/dcodeGallery/Dcode_gallery_6.png"
import AppleIco from "../../../images/apple.svg"
import AndroidIco from "../../../images/android.svg"
import AppLightbox from "../../../components/shared/Lightbox/AppLightbox";
import Breadcrumbs from "../../../components/shared/Breadcrumbs/Breadcrumbs";
import BackToMobileAppsButton from "../../../components/shared/ButtonBackTo/BackToMobileAppsButton";

const query = graphql`
{
  fluid: allFile(filter: {sourceInstanceName: {eq: "images_sections_dcode_gallery"}}) {
    nodes {
      childImageSharp {
        fluid(maxWidth: 3400, quality: 70) {
          ...GatsbyImageSharpFluid,
          originalName
        }
      }
    }
  }
}`;

const DcodeGallery = () => {
    const routes = getRoutes();
    const pageTitle = routes.TechnologyMobileApps.pageTitle;
    const imagesFluid = getImagesFluid(useStaticQuery(query));
    const dcodeReaderSectionAnchor = routes.Technology.sections.mobileApps.subSections.dcodeReader.anchor;
    const dcodeMobilAppsAnchor = routes.Technology.sections.mobileApps.anchor;

    const [openLightbox, setOpenLightbox] = useState(false);

    const platforms = {
        ios: AppleIco,
        android: AndroidIco,
    }

    const renderPlatforms = (platforms = []) => {
        return platforms.map((platform, index) => {
            return <img key={`platform_${index}`} src={platform} alt=""/>;
        })
    }

    const images = [
        {src: dcodeGallery1, fileName: 'Dcode_gallery_1.jpg', alt: "Dcode 1", platforms: [platforms.ios]},
        {src: dcodeGallery2, fileName: 'Dcode_gallery_2.jpg', alt: "Dcode 2", platforms: [platforms.ios]},
        {src: dcodeGallery3, fileName: 'Dcode_gallery_3.jpg', alt: "Dcode 3", platforms: [platforms.ios]},
        {src: dcodeGallery4, fileName: 'Dcode_gallery_4.png', alt: "Dcode 4", platforms: [platforms.android, platforms.ios]},
        {src: dcodeGallery5, fileName: 'Dcode_gallery_5.png', alt: "Dcode 5", platforms: [platforms.android, platforms.ios]},
        {src: dcodeGallery6, fileName: 'Dcode_gallery_6.png', alt: "Dcode 6", platforms: [platforms.android, platforms.ios]},
    ]
    const [lightboxImages, setLightboxImages] = useState([]);

    const mapImageForPreview = (image) => {
        return {
            ...image,
            title: <div className={css.fullscreenPreviewTitle}>{renderPlatforms(image.platforms)}</div>
        }
    }

    const showPreview = (index) => {
        setOpenLightbox(true);
        const next = images.filter((value, i) => i > index)
        const prev = images.filter((value, i) => i < index)
        setLightboxImages([
            mapImageForPreview(images[index]),
            ...next.map(image => mapImageForPreview(image)),
            ...prev.map(image => mapImageForPreview(image))
        ])
    };

    const onLightboxClose = () => {
        setOpenLightbox(false);
    };

    const renderSection = (imageObj, index) => {
        const {alt = '', fileName, src, platforms} = imageObj;
        return (
            <div className={css.section} key={`image_${index}`}>
                <div className={css.sectionImageWrapper} onClick={() => showPreview(index)}>
                    {renderImageByFileName(imagesFluid, fileName, alt)}
                </div>
                {platforms.length > 0 && <div className={css.smallPreviewPlatforms}>{renderPlatforms(platforms)}</div>}
            </div>
        );
    };

    return (
        <Layout>
            <SEO title={routes.TechnologyMobileAppsDcodeGallery.pageTitle}/>

            <Breadcrumbs
                title={routes.TechnologyMobileAppsDcodeGallery.pageTitle}
                parentTitles={[routes.Technology.pageTitle, pageTitle]}
                parentRoutes={[routes.Technology, routes.TechnologyMobileApps]}
                sectionAnchors={[dcodeMobilAppsAnchor, dcodeReaderSectionAnchor]}
            />

            <div className={css.wrapper}>
                <div className={css.title}>
                    {routes.TechnologyMobileAppsDcodeGallery.pageTitle}
                </div>
                <div className={css.text}>
                    <div>Check out Dcode Reader in action!</div>
                </div>
                <div className={css.dcodeInstructionWrapper}>
                    <div className={css.wrapperBg}/>
                    <div className={css.content}>
                        <div className={css.bigContent}>
                            {renderImageByFileName(imagesFluid, 'dcode_instruction.png', '')}
                        </div>
                        <div className={css.smallContent}>
                            {renderImageByFileName(imagesFluid, 'dcode_instruction_m.png', '')}
                        </div>
                    </div>
                </div>
                <div className={css.sectionsWrapper}>
                    {images.map((image, index) => renderSection(image, index))}
                </div>
                <BackToMobileAppsButton
                    sectionAnchor={dcodeReaderSectionAnchor}/>

                <AdditionalInformation/>

                <AppLightbox
                    open={openLightbox}
                    close={onLightboxClose}
                    slides={lightboxImages}
                />

                <Footer/>

            </div>
        </Layout>
    );
};

export default DcodeGallery;
